import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Suspense, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getCampaignInfo, stripeCheckoutSession } from '@jaramba-frontend/core/api';
import { BillingIntervals, Campaigns } from '@jaramba-frontend/core/types';

import { LoadingScreen, Products, SubscriptionInfo, CancelSwedbankSubscriptionModal } from '..';
import { cancelSwedbankSubscription, stripeSubscriptionManagementSession } from '../../api';
import { routes } from '../../constants';
import { clearProduct, setProduct } from '../../store/features/product/productSlice';
import { useAppDispatch } from '../../store/hooks';
import type { RootState } from '../../store/store';
import { SubscriptionStatuses } from '../../types';

interface Props {
    onUpdateUserInfo: () => Promise<void>;
}

const Subscription = ({ onUpdateUserInfo }: Props) => {
    const dispatch = useAppDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const { product } = useSelector((state: RootState) => state.product);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isSwedbankCancellationModalOpen, setIsSwedbankCancellationModalOpen] = useState<boolean>(false);

    const hasActiveSubscription = user?.userInfo?.ProductKind === SubscriptionStatuses.ActiveSubscription;
    const hasCancelledSubscription = user?.userInfo?.ProductKind === SubscriptionStatuses.CancelledSubscription;
    const hasNoSubscription = user?.userInfo?.ProductKind === SubscriptionStatuses.NoSubscription;

    const storedCampaignName = sessionStorage.getItem('campaignName') ?? undefined;

    // HACK: Due to limitations in the available subscription data for some older Apple users,
    // we are using a workaround to determine if the user has an active subscription.
    // This workaround relies on checking if the next payment date has expired,
    // with an added grace period for Apple subscriptions.
    // This approach may not be accurate in all cases and should be revisited.
    let hasExpiredPayment = false;

    // Grace period durations are dependent on the subscription period length as follows:
    // 6 days for a weekly subscription and 16 days for monthly and longer subscriptions.
    // The maximum timezone difference can be more than 24 hours and 18 days will definitely cover it
    const gracePeriodDays = 18;

    if (user && user.userInfo) {
        hasExpiredPayment = dayjs(user.userInfo.NextPayment + 'T00:00:00.000Z')
            .add(gracePeriodDays, 'day')
            .isBefore(dayjs());
    }

    const fetchCampaignInfo = async () => {
        try {
            const { prices, campaign } = await getCampaignInfo(
                storedCampaignName || (hasCancelledSubscription ? Campaigns.Reactivate : '')
            );

            dispatch(
                setProduct({
                    campaign,
                    prices,
                })
            );
        } catch (err) {
            console.error(err);

            if (err instanceof AxiosError) {
                if (err.response?.status === 404) {
                    await fetchCampaignInfo();
                } else {
                    throw new Error('Failed to fetch products');
                }
            }
        }
    };

    useEffect(() => {
        /** Fetch campaign in cases of:
            - No active subscription and no product
            - Cancelled subscription and campaign is not Reactivate
            - Expired payment
            - Campaign name has changed (for users who got email with campaign name in URL)
        */
        if (
            (!hasActiveSubscription && !product) ||
            (hasCancelledSubscription && product?.campaign.name !== Campaigns.Reactivate) ||
            hasExpiredPayment ||
            (storedCampaignName && product?.campaign && product.campaign.name !== storedCampaignName)
        ) {
            fetchCampaignInfo();
        }
    }, [user?.userInfo?.ProductKind]);

    const handleToggleSwedbankSubscriptionCancelModal = (open: boolean) => {
        setIsSwedbankCancellationModalOpen(open);
    };

    const handleCancelSwedbankSubscription = async () => {
        try {
            setLoading(true);

            await cancelSwedbankSubscription();
            await onUpdateUserInfo();
        } catch (err) {
            console.error(err);
            setErrorMessage('Det gick inte att säga upp Swedbank Pay-prenumeration. Försök igen senare.');
            throw new Error('Failed to cancel Swedbank Pay subscription');
        } finally {
            setLoading(false);
            setIsSwedbankCancellationModalOpen(false);
        }
    };

    const clearProductAndRedirect = (redirectUrl?: string) => {
        if (redirectUrl) {
            dispatch(clearProduct());
            window.location.href = redirectUrl;
        } else {
            throw new Error('No redirect url');
        }
    };

    const handleRedirectToStripeSubscriptionManagement = async () => {
        try {
            setLoadingScreen(true);
            const redirectUrl = await stripeSubscriptionManagementSession();
            clearProductAndRedirect(redirectUrl);
        } catch {
            setLoadingScreen(false);
        }
    };

    const handleSelectPlanClick = async (billingInterval: BillingIntervals) => {
        try {
            setLoading(true);
            setLoadingScreen(true);

            const redirectUrl = await stripeCheckoutSession({
                token: user?.token ?? '',
                successUrl: `${window.location.origin}${routes.DOWNLOAD}`,
                cancelUrl: `${window.location.origin}${routes.HOME}`,
                priceLookupKey: product?.prices.find((price) => price.billingInterval.toLowerCase() === billingInterval)
                    ?.key as string,
                campaignId: product?.campaign.name,
            });

            clearProductAndRedirect(redirectUrl);
        } catch (err) {
            setLoading(false);
            setLoadingScreen(false);
            console.error(err);
            setErrorMessage('Det gick inte att begära omdirigering till Stripe. Försök igen senare.');
            throw new Error('Failed to request redirect to Stripe');
        }
    };

    if (loadingScreen) {
        return <LoadingScreen />;
    }

    return (
        <>
            {hasActiveSubscription && !hasExpiredPayment && (
                <SubscriptionInfo
                    loading={loading || loadingScreen}
                    errorMessage={errorMessage}
                    onToggleSwedbankSubscriptionCancelModal={handleToggleSwedbankSubscriptionCancelModal}
                    onManageSubscriptionClick={handleRedirectToStripeSubscriptionManagement}
                />
            )}

            {(hasNoSubscription || hasCancelledSubscription || hasExpiredPayment) && (
                <Products onSelectPlanClick={handleSelectPlanClick} />
            )}

            {isSwedbankCancellationModalOpen && (
                <Suspense fallback={null}>
                    <CancelSwedbankSubscriptionModal
                        isOpen={isSwedbankCancellationModalOpen}
                        onAccept={handleCancelSwedbankSubscription}
                        onClose={() => handleToggleSwedbankSubscriptionCancelModal(false)}
                    />
                </Suspense>
            )}
        </>
    );
};

export default Subscription;

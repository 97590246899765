import { useSelector } from 'react-redux';

import { Paper } from '@jaramba-frontend/core/components';

import type { RootState } from '../../store/store';
import { PaymentProviders, SubscriptionStatuses } from '../../types';
import Chip from '../Chip';
import styles from './styles.module.scss';

interface Props {
    loading: boolean;
    errorMessage: string;
    onToggleSwedbankSubscriptionCancelModal: (open: boolean) => void;
    onManageSubscriptionClick: () => void;
}

const SubscriptionInfo = ({
    loading,
    errorMessage,
    onToggleSwedbankSubscriptionCancelModal,
    onManageSubscriptionClick,
}: Props) => {
    const { user } = useSelector((state: RootState) => state.user);

    const hasActiveSubscription = user?.userInfo?.ProductKind === SubscriptionStatuses.ActiveSubscription;
    const hasCancelledSubscription = user?.userInfo?.ProductKind === SubscriptionStatuses.CancelledSubscription;

    // Only for Swedbank Pay provider
    const isSubscriptionCancelled = user?.userInfo?.IsSubscriptionCancelled;

    const hasStripeProvider = user?.userInfo?.PaymentProvider === PaymentProviders.Stripe;
    const hasGoolePlayProvider = user?.userInfo?.PaymentProvider === PaymentProviders.GooglePlay;
    const hasAppStoreProvider =
        user?.userInfo?.PaymentProvider === PaymentProviders.Itunes ||
        user?.userInfo?.PaymentProvider === PaymentProviders.Apple;
    const hasSwedBankPayProvider = user?.userInfo?.PaymentProvider === PaymentProviders.SwedbankPay;
    const isInAppPurchase = hasGoolePlayProvider || hasAppStoreProvider;

    return (
        <Paper className={styles.card}>
            <div className={styles.cardHeader}>
                <h4 className={styles.cardTitle}>Din prenumerationsplan</h4>
                <Chip
                    isGreen={hasActiveSubscription && !isInAppPurchase}
                    isRed={hasCancelledSubscription || (hasActiveSubscription && isInAppPurchase)}
                >
                    {hasActiveSubscription && !isInAppPurchase && 'Aktiv'}
                    {hasActiveSubscription &&
                        isInAppPurchase &&
                        hasAppStoreProvider &&
                        'Dina betalningar hanteras via App Store'}
                    {hasActiveSubscription &&
                        isInAppPurchase &&
                        hasGoolePlayProvider &&
                        'Dina betalningar hanteras via Google Play'}
                    {hasCancelledSubscription && 'Avbruten'}
                </Chip>
            </div>

            <div className={styles.separator} />

            {isInAppPurchase && (
                <p className={styles.cardText}>
                    Prenumerationen kommer automatiskt förnyas varje månad via ditt{' '}
                    {hasAppStoreProvider ? 'App Store' : 'Google Play'}-konto tills du väljer att avsluta. Det kan du
                    själv göra när som helst under dina prenumerationsinställningar i{' '}
                    {hasAppStoreProvider ? 'App Store' : 'Google Play'}. Ändringar kan ta upp till 24 timmar innan de
                    slår igenom.
                </p>
            )}

            {hasActiveSubscription && hasStripeProvider && (
                <button className={styles.cardButton} disabled={loading} onClick={onManageSubscriptionClick}>
                    Ändra / avbryta prenumeration
                </button>
            )}

            {hasActiveSubscription && hasSwedBankPayProvider && !isSubscriptionCancelled && (
                <button
                    className={styles.cardButton}
                    disabled={loading}
                    onClick={() => onToggleSwedbankSubscriptionCancelModal(true)}
                >
                    Avsluta prenumeration
                </button>
            )}

            {hasActiveSubscription && hasSwedBankPayProvider && isSubscriptionCancelled && (
                <p className={styles.cardText}>
                    Din prenumeration kommer <b>avslutas</b> den <b>{user?.userInfo?.LastDayOfService}</b> och du kommer
                    inte längre att kunna använda tjänsten efter detta datum.
                    <br />
                    <br />
                    Välkommen åter!
                </p>
            )}

            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        </Paper>
    );
};

export default SubscriptionInfo;

import type { AxiosError } from 'axios';

import { apiService } from '.';
import type {
    GetSubscriptionInfoResponse,
    StripeSubscriptionManagementSessionRequest,
    StripeSubscriptionManagementSessionResponse,
    SubscriptionInfo,
} from '../types';

export const getSubscriptionInfo = async (): Promise<SubscriptionInfo> => {
    try {
        const res = await apiService.get<GetSubscriptionInfoResponse>('/payments/customers/current-subscription', {
            apiVersion: 1,
        });

        if (!res) {
            return Promise.reject();
        }

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};

export const stripeSubscriptionManagementSession = async (): Promise<string> => {
    try {
        const res = await apiService.post<
            StripeSubscriptionManagementSessionResponse,
            StripeSubscriptionManagementSessionRequest
        >(
            '/payments/stripe/billing-portal-session',
            {
                ReturnUrl: window?.location.href,
            },
            {
                apiVersion: 1,
            }
        );

        if (!res) {
            return Promise.reject();
        }

        return Promise.resolve(res.redirectUrl);
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};

export const cancelSwedbankSubscription = async (): Promise<void> => {
    try {
        await apiService.get('/webapp/accounts/cancelSubscription');

        return Promise.resolve();
    } catch (err) {
        return Promise.reject(err as AxiosError);
    }
};

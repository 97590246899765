import { SharedApiService } from '@jaramba-frontend/core/services';

import { getStoreState } from '../store/store';

const getJarambaClient = async () => {
    const token = getStoreState().user.user?.token;

    return {
        'jaramba-client': process.env.REACT_APP_JARAMBA_CLIENT,
        Authorization: `Bearer ${token}`,
    };
};

export const apiService = new SharedApiService([getJarambaClient]);

export * from './auth';
export * from './subscription';
export * from './user';

import dayjs from 'dayjs';
import { Suspense, lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Tabs } from '@jaramba-frontend/core/components';
import { BillingIntervals } from '@jaramba-frontend/core/types';

import { getSubscriptionInfo, getUserInfo, getUserStorageValue } from '../../api';
import { AppHeader, AccountInfo as AccountInfoTab, Subscription as SubscriptionTab } from '../../components';
import { setUserInfo, setSubscriptionInfo, setStoredKeyValues } from '../../store/features/user/userSlice';
import { useAppDispatch } from '../../store/hooks';
import type { RootState } from '../../store/store';
import { PaymentProviders, SubscriptionStatuses, UserStorageKey } from '../../types';

const AnnualNoticeModal = lazy(() => import('../../components/AnnualNoticeModal'));

const Home = () => {
    const dispatch = useAppDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const isUserWithoutSubscription =
        user?.userInfo?.ProductKind === SubscriptionStatuses.NoSubscription ||
        user?.userInfo?.ProductKind === SubscriptionStatuses.CancelledSubscription;
    const isRegisteredMoreThanDayAgo = !!dayjs().diff(dayjs(user?.subscriptionInfo?.created), 'day');
    const isNextPaymentLessThanMonth = !dayjs().diff(dayjs(user?.subscriptionInfo?.nextBillingDate), 'month');
    const isNextPaymentLessThanFiveDays = !!(dayjs().diff(dayjs(user?.subscriptionInfo?.nextBillingDate), 'day') > -5);
    const hasStripeProvider = user?.userInfo?.PaymentProvider === PaymentProviders.Stripe;
    const showAnnualNoticeModal =
        hasStripeProvider &&
        !isUserWithoutSubscription &&
        user?.subscriptionInfo?.billingInterval?.toLowerCase() === BillingIntervals.Annualy &&
        user.subscriptionInfo.autoRenewalEnabled &&
        user.storedKeyValues?.annualNoticeModalNextBillingDate !== undefined &&
        user.storedKeyValues.annualNoticeModalNextBillingDate !== user.subscriptionInfo.nextBillingDate &&
        ((isNextPaymentLessThanMonth && isRegisteredMoreThanDayAgo) || isNextPaymentLessThanFiveDays);

    const updateUserInfo = async () => {
        try {
            const userInfo = await getUserInfo();
            dispatch(setUserInfo(userInfo));

            if (userInfo.ProductKind === SubscriptionStatuses.ActiveSubscription) {
                const subscriptionInfo = await getSubscriptionInfo();
                dispatch(setSubscriptionInfo(subscriptionInfo));

                if (user?.storedKeyValues?.annualNoticeModalNextBillingDate !== subscriptionInfo.nextBillingDate) {
                    const newValue = await getUserStorageValue(UserStorageKey.AnnualNoticeModalNextBillingDate);
                    dispatch(
                        setStoredKeyValues({
                            annualNoticeModalNextBillingDate: newValue,
                        })
                    );
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        updateUserInfo();
    }, []);

    return (
        <>
            <AppHeader title="Mitt konto" />
            <Tabs
                activeTabIndex={Number(isUserWithoutSubscription)}
                tabs={[
                    {
                        title: 'Mina uppgifter',
                        content: <AccountInfoTab />,
                    },
                    {
                        title: 'Prenumeration',
                        content: <SubscriptionTab onUpdateUserInfo={updateUserInfo} />,
                    },
                ]}
            />

            {showAnnualNoticeModal && (
                <Suspense fallback={null}>
                    <AnnualNoticeModal />
                </Suspense>
            )}
        </>
    );
};

export default Home;

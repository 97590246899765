import { apiService } from '.';
import { DatoCMS } from '../services';
import type { Campaign, GetProductsResponse, Price, Product } from '../types';

const getProducts = async (campaignId?: string) => {
    const campaignParam = campaignId ? `?campaignId=${campaignId}` : '';
    return await apiService.get<GetProductsResponse>(`/payments/stripe/products${campaignParam}`, { apiVersion: 1 });
};

export const getCampaignInfo = async (campaignName?: string) => {
    const data: { campaign: Campaign } = await DatoCMS.requestContent({
        query: `
            query GetCampaign($filter: CampaignModelFilter) {
                campaign(filter: $filter) {
                    name
                    pageTitle
                    pageSubtitle
                    debitDate
                    plans {
                        offerText
                        billingInterval
                    }
                    
                }
            }
        `,
        variables: {
            filter: {
                ...(campaignName
                    ? {
                          name: {
                              eq: campaignName,
                          },
                      }
                    : { isDefaultCampaign: { eq: 'true' } }),
            },
        },
    });

    const products: { products: Product[] } | undefined = await getProducts(campaignName);
    const prices: Price[] = products?.products?.[0]?.prices;

    return {
        prices,
        campaign: data.campaign,
    };
};

export const getCampaignHeroBanner = async (campaignName?: string) => {
    const data: { campaign: Campaign } = await DatoCMS.requestContent({
        query: `
            query GetCampaign($filter: CampaignModelFilter) {
                campaign(filter: $filter) {
                    pageTitle
                    pageSubtitle
                    
                }
            }
        `,
        variables: {
            filter: {
                ...(campaignName
                    ? {
                          name: {
                              eq: campaignName,
                          },
                      }
                    : { isDefaultCampaign: { eq: 'true' } }),
            },
        },
    });

    return {
        title: data?.campaign?.pageTitle ?? '',
        subtitle: data?.campaign?.pageSubtitle ?? '',
    };
};

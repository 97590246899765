import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosInstance } from 'axios';

type HeaderMiddlewareType = () => PromiseLike<Record<string, string | number | undefined>>;

interface RequestConfig extends AxiosRequestConfig {
    apiVersion?: number;
    returnHeaders?: boolean;
}

class SharedApiService {
    private readonly defaultApiVersion = 3;
    private readonly axios: AxiosInstance;

    constructor(headerMiddlewares: HeaderMiddlewareType[] = [], onAuthError?: () => Promise<void>) {
        const baseURL =
            process.env.REACT_APP_SERVICE_URL ??
            process.env.NEXT_PUBLIC_SERVICE_URL ??
            process.env.REACT_APP_MOCK_BACKEND;
        if (!baseURL) {
            throw new Error('API_URL variable is not configured');
        }

        this.axios = axios.create({ baseURL });

        this.axios.interceptors.request.use(async (config) => {
            const dynamicHeaders = await Promise.all(headerMiddlewares.map((middleware) => middleware()));

            dynamicHeaders.forEach((headers) => {
                config.headers = {
                    ...headers,
                    ...config.headers,
                } as AxiosRequestHeaders;
            });

            return { ...config };
        });

        if (onAuthError) {
            this.axios.interceptors.response.use(
                (response) => response,
                async (error) => {
                    if (error?.response?.status === 401) {
                        await onAuthError();
                    }
                }
            );
        }
    }

    private async request<T>(config: RequestConfig): Promise<T> {
        const apiVersion = config.apiVersion ?? this.defaultApiVersion;
        if (apiVersion > 0) {
            config.url = `/v${apiVersion}${config.url}`;
        }

        try {
            const res = await this.axios(config);

            if (config.returnHeaders) {
                return { ...res?.data, headers: res.headers };
            }

            return res?.data;
        } catch (error) {
            if (error instanceof Error) {
                console.error(error);
            }

            throw error;
        }
    }

    async get<T>(url: string, config?: RequestConfig): Promise<T> {
        return this.request({ method: 'GET', url, ...config });
    }

    async post<T, D = any>(url: string, data: D, config?: RequestConfig): Promise<T> {
        return this.request({ method: 'POST', url, data, ...config });
    }

    async put<T, D = any>(url: string, data: D, config?: RequestConfig): Promise<T> {
        return this.request({ method: 'PUT', url, data, ...config });
    }

    async patch<T, D = any>(url: string, data: D, config?: RequestConfig): Promise<T> {
        return this.request({ method: 'PATCH', url, data, ...config });
    }

    async delete<T>(url: string, config?: RequestConfig): Promise<T> {
        return this.request({ method: 'DELETE', url, ...config });
    }
}

export default SharedApiService;

export const COOKIE_CONSENT_NAME = 'jarambaCookies';

export const UTM_COOKIE_NAME = 'utm';

export enum UtmParams {
    UTM_SOURCE = 'utm_source',
    UTM_CONTENT = 'utm_content',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
}

export const ABTests = {
    ProductsPageHeroBulletPoints: {
        id: 'products-page-hero-bullet-points',
        mixpanelId: 'website_productsPageHeroBulletPoints_300724',
    },
};

import { Outlet } from 'react-router-dom';

import { mobileBreakpoint } from '@jaramba-frontend/core/constants';
import { useMediaMatch } from '@jaramba-frontend/core/hooks';

import AppHeaderMobile from '../AppHeaderMobile';
import Sidebar from '../Sidebar';
import styles from './styles.module.scss';

const AppLayout = () => {
    const isMobile = useMediaMatch(mobileBreakpoint);

    return (
        <div className={styles.appWrapper}>
            {isMobile ? <AppHeaderMobile /> : <Sidebar />}

            <div className={styles.pageWrapper}>
                <Outlet />
            </div>
        </div>
    );
};

export default AppLayout;
